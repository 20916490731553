import { Component } from 'react';

class JoinHandler extends Component {

  componentDidMount() {
    // 로그인 페이지 이동
    window.parent.location.replace('/popup/login');
  }

  render() {
    return null;
  }
};

export default JoinHandler;